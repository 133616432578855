import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import "./badge-relative.css"
import useQuery from '../admin/useQuery'

import {
  useParams
} from "react-router-dom"

export default function Badge(props) {

  const [pk] = useState(initialize())
  const [badgeData, setBadgeData] = useState()

  let query = useQuery()
  let style = query.get('style')

  function initialize() {
    let { id } = useParams()
    return props.pk ?? id
  }

  useEffect(() => {
    if (pk) { load() }
  }, [pk])


  async function load() {
    if (!pk) { return }
    try {
      let response = await API.get("badgePreview", "/preview/" + pk)
      setBadgeData(response)

      if (style === 'preview') {
        await API.put("item", "/badge/" + pk + "/previewed")
      } else if (style === 'print') {
        await API.put("item", "/badge/" + pk + "/printed")
      }
    }
    catch (error) {
      console.log('error', error)
      setBadgeData(false)
    }
  }

  if (!badgeData) { return null }

  const badgeOrganization = props.organization ?? badgeData.badgeOrganization
  const badgeNickname = props.badgeNickname ?? badgeData.badgeNickname

  const showExhibitor = badgeData.registrationType.pk === 'f.2368bfcd-c7a5-4ab6-b566-efc75d9d3662' ||
    badgeData.registrationType.pk === 'f.df1be03e-4769-4d30-a808-485fd5a5df8b' ||
    badgeData.registrationType.pk === 'f.dfd75fa2-8591-44d0-82dc-309ae64b0dd5' ||
    badgeData.forceExhibitor
  const showSecurity = badgeData.showSecurity
  const badgeName = badgeNickname ? badgeNickname : badgeData.firstName
  const name =
    (badgeData.militaryServiceRank.pk && !badgeData.militaryServiceRank.doNotIncludeInFullName ? badgeData.militaryServiceRank.abbreviation + ' ' : '') +
    (badgeData.lastName ? ' ' + badgeData.lastName : '') +
    (badgeData.suffix ? ' ' + badgeData.suffix : '') +
    (badgeData.militaryServiceStatus.pk === 'z.f003b767-8e20-402c-b2b1-e07cdeddc576' ? ' (Ret)' : '')

  let organization
  if (badgeData.afOrg && badgeData.afOrg.pk) {
    organization = badgeOrganization ? badgeOrganization : badgeData.afOrg.name
  } else {
    organization = badgeOrganization ? badgeOrganization : badgeData.organization
  }
  if ((badgeData.registrationType.pk === 'f.2368bfcd-c7a5-4ab6-b566-efc75d9d3662' ||
    badgeData.registrationType.pk === 'f.df1be03e-4769-4d30-a808-485fd5a5df8b' ||
    badgeData.registrationType.pk === 'f.dfd75fa2-8591-44d0-82dc-309ae64b0dd5') && badgeData.exhibitor.name) {
    organization = badgeOrganization ? badgeOrganization : badgeData.exhibitor.name
  }

  let base = badgeData.base.otherName ? badgeData.base.otherName : badgeData.base.name
  base = badgeData.badgeBaseName ? badgeData.badgeBaseName : base

  // let style = (props.badgePosition === 0 && props.badgePage === 1) ? {top: "40mm"} : {}

  const badgeClass = props.preview ? " badge-preview" : ""
  const badgePreviewScale = props.preview ? "badge-preview-scale" : ""
  const exhibitorClass = props.preview ? " exhibitor-no-preview" : ""

  return (
    <div className={"badge " + badgePreviewScale}>
      <div className={badgeClass}>
        <div className="badge-row-1">
          {showExhibitor && <div className="exhibitor">EXHIBITOR</div>}
          {!showExhibitor && <div className={"exhibitor-no" + exhibitorClass}>EXHIBITOR</div>}
          {showSecurity && <div className="security"></div>}
        </div>

        <div className="badge-row-2">
          <div className="first-name">{badgeName}</div>
        </div>

        <div className="badge-row-3">
          <div className="title">{name}</div>
        </div>

        <div className="badge-row-4">
          <div className="organization">{organization}</div>
        </div>

        <div className="badge-row-5">
          <div className="base">{base}</div>
        </div>

        <div className="badge-row-6">
          <span className="fullName">{badgeData.fullName}</span>
          <span className="guest-of">{badgeData.gsi2_pk && badgeData.gsi2_pk.slice(0, 1) === 'r' ? <span>Guest Of {badgeData.badgeGuestOfName}</span> : <span></span>}</span>
        </div>
      </div>
    </div>
  )

}
