import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Legend, Cell, BarChart, CartesianGrid, Tooltip, Bar, YAxis, XAxis } from 'recharts'
//import NumberFormat from 'react-number-format'
import { Link } from "react-router-dom"
// import { scaleOrdinal } from 'd3-scale';
// import { schemeDark2 } from 'd3-scale-chromatic';

import Panel from "../Panel"

export default function Summary(props) {

  const [registrationCounts, setRegistrationCounts] = useState(props.registrationCounts)
  const [registrationStatus, setRegistrationStatus] = useState(props.registrationStatus.data)
  const [registrationYears, setRegistrationYears] = useState('')
  //  const [conventionActive] = useState(false)

  useEffect(() => {
    setRegistrationCounts(props.registrationCounts)
    if (props.registrationCounts.items.length > 3) {
      setRegistrationYears(props.registrationCounts.items[3].conventionYears[0])
    }
  }, [props.registrationCounts])

  useEffect(() => {
    setRegistrationStatus(props.registrationStatus.data)
  }, [props.registrationStatus])

  const className = registrationStatus && registrationStatus.registrationActive ? "usa-button" : "usa-button usa-button--disabled"

  // If registration is not active and there are zero registrations, then don't display the counts
  // If registration is not active but there are counts don't return anything yet. This is typical
  // after the registration period has closed after the convention.
  if (!registrationStatus.registrationActive) {
    if (registrationCounts.items && registrationCounts.items[0] && registrationCounts.items[0].registrationTotal === 0) {
      return null
    }
  }

  if (registrationCounts.items && registrationCounts.items[0] && registrationCounts.items[0].registrationTotal === 0) {
    return (
      <Panel title={"Registrations"} style={{ width: '50%' }}>
        <div className="usa-alert usa-alert--info" style={{ marginTop: 20 }}>
          <div className="usa-alert__body">
            <h4 className="usa-alert__heading">No Registrations Yet</h4>
            <p className="usa-alert__text">
              Registration totals and graphs will show here as they are added.
            </p>
          </div>
        </div>
      </Panel>
    )
  }

  return (
    <Panel title={"Registrations " + registrationYears} style={{ width: '50%' }}>
      <div>
        <Link className={className} to="/admin/registration/new">
          Add Registration
        </Link>
        <Link
          className="usa-tooltip usa-button"
          disabled={registrationCounts.isLoading && registrationCounts.items.length === 0}
          title="Reload Counts"
          onClick={() => registrationCounts.resetData()}
        >
          <i className="fas fa-redo-alt"></i>
        </Link>
        <span style={{ paddingRight: 10 }} />
        {registrationCounts.isLoading &&
          <i className="fas fa-circle-notch fa-spin"></i>
        }
        {registrationCounts.items.length > 0 &&
          <span>Last Refreshed {registrationCounts.loadedTime.format('YYYY-MM-DD h:mm a')}</span>
        }
        <span style={{ paddingRight: 10 }} />
        {registrationCounts.isErrored &&
          <span style={{ color: '#b50908' }}><i className="fas fa-exclamation"></i> An error occurred loading the data</span>
        }
      </div>
      {registrationCounts.items.length > 0 && !registrationCounts.isErrored &&
        <>
          <div>
            <h2>All Registrations</h2>
            <div style={{ width: 500, display: 'inline-block' }}>
              <PieChart width={500} height={220}>

                <Pie
                  data={registrationCounts.items[0].pieChart}
                  dataKey="value"
                  cx={160}
                  cy={100}
                  startAngle={180}
                  endAngle={-180}
                  outerRadius={100}
                  label
                  isAnimationActive={false}
                >
                  {
                    registrationCounts.items[0].pieChart.map((entry, index) => (
                      <Cell
                        key={`slice-${index}`}
                        fill={entry.color}
                      />
                    ))
                  }
                </Pie>
              </PieChart>
            </div>
            <div style={{ width: 500, display: 'inline-block' }}>
              <div style={{ display: 'inline-block', position: 'relative', height: 0, left: 100, top: -120, fontSize: 'large', fontWeight: 600 }}>
                <Legend layout="vertical" width={600} height={100} payload={registrationCounts.items[0].pieChartLabels} />
              </div>
            </div>
          </div>
          <div>
            <h2>Registration Types</h2><div className="usa-prose" style={{ marginTop: -20 }}><em>Complete registrations only.</em></div>
            <div style={{ width: 500, display: 'inline-block' }}>
              <PieChart width={500} height={300}>
                <Pie
                  data={registrationCounts.items[1].pieChart}
                  dataKey="value"
                  cx={160}
                  cy={140}
                  startAngle={180}
                  endAngle={-180}
                  outerRadius={100}
                  label
                  isAnimationActive={false}
                >
                  {
                    registrationCounts.items[1].pieChart.map((entry, index) => (
                      <Cell
                        key={`slice-${index}`}
                        fill={entry.color}
                      />
                    ))
                  }
                </Pie>
              </PieChart>
            </div>
            <div style={{ width: 500, display: 'inline-block' }}>
              <div style={{ display: 'inline-block', position: 'relative', height: 0, left: 100, top: -160, fontSize: 'large', fontWeight: 600 }}>
                <Legend layout="vertical" width={600} height={100} payload={registrationCounts.items[1].pieChartLabels} />
              </div>
            </div>
          </div>
          <div>
            <h2>Registrations By Day</h2><div className="usa-prose" style={{ marginTop: -20 }}><em>Complete registrations only.</em></div>
            <BarChart width={1100} height={250} data={registrationCounts.items[2].completeHistogram} maxBarSize={50} barSize={50}>
              <CartesianGrid horizontal={false} />
              <YAxis type="number" />
              <XAxis padding={{ left: 20, right: 100 }} dataKey="date" />
              <Tooltip />
              <Bar dataKey="value" fill="#1256aa" maxBarSize={15} isAnimationActive={false} />
            </BarChart>
          </div>
        </>
      }


    </Panel>
  )
}
