import React from 'react'
import { Link } from "react-router-dom"

export default function Hero() {

  return (
    <section className="usa-hero" style={{ backgroundImage: 'url(/images/hero-audience.png)' }}>
      <div className="grid-container">
        <div className="usa-hero__callout">
          <h1 className="usa-hero__heading">
            <span className="usa-hero__heading--alt">Registration Open</span>
          </h1>
          <p>Registration for our 2024 Convention and Symposium is open.</p>
          <Link
            to="/convention2024"
            title="Register"
            aria-label="Register"
            className="usa-button"
          >
            Register
          </Link>
        </div>
      </div>
    </section>
  )
}
